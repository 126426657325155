import { useRef, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import useLocalStorageState from 'use-local-storage-state';

// :: Hoc
import Authentication from '../../hoc/Authentication/Authentication';

// :: Form
import LoginForm from '../../form/LoginForm/LoginForm';

// :: Helpers
import { fetchLogin } from '../../lib/flotiq-client/fetch-login';
import {
  getMaskedOrganizationName,
  getLoginErrorText,
  getTestProps,
} from '../../lib/helpers';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const Login = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const activated = searchParams.get('activated');
  const deleted = searchParams.get('deleted');
  const status = searchParams.get('status');
  const [darkMode] = useDarkMode();
  const [, setUser] = useLocalStorageState('cms.user');

  const toastRef = useRef();
  const loginSentRef = useRef();

  const error = useMemo(
    () => getLoginErrorText(status, t, activated),
    [t, activated, status],
  );

  const navigateUrl = useMemo(() => {
    if (searchParams.get('redirect_uri')) {
      const searchParmasString = searchParams.toString();
      return '/' + (searchParmasString ? `?${searchParmasString}` : '');
    }
    return '/';
  }, [searchParams]);

  useEffect(() => {
    if (toastRef.current) {
      toast.remove(toastRef.current);
    }
    if (activated === 'true') {
      toastRef.current = toast.success(t('Activation.True'));
    } else if (deleted === 'true') {
      toastRef.current = toast.success(t('AccountDeleted.True'));
    } else if (deleted === 'false') {
      toastRef.current = toast.error(t('AccountDeleted.False'), {
        duration: Infinity,
      });
    } else if (error) {
      toastRef.current = toast.error(error, {
        duration: Infinity,
      });
    }
  }, [activated, deleted, error, t]);

  useEffect(() => {
    const tokenParam = searchParams.get('token');
    const registeredParam = searchParams.get('registered');

    if (tokenParam == null) return;
    try {
      const userData = window.atob(tokenParam);
      const userDataObject = JSON.parse(userData);
      setUser(userDataObject);

      if (!loginSentRef.current) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'login',
            user_id: userDataObject.data?.id,
            organization_id: userDataObject.data?.organization,
            organization_name: getMaskedOrganizationName(
              userDataObject.data?.organization_name,
            ),
            plan_id: userDataObject.data?.limits_plan?.id,
            plan_name: userDataObject.data?.limits_plan?.name,
            space_id: userDataObject.data?.default_space?.id,
            user_roles: userDataObject.data?.roles,
          },
        });
        loginSentRef.current = true;
      }

      if (registeredParam)
        TagManager.dataLayer({
          dataLayer: {
            event: 'sign_up',
          },
        });

      navigate(navigateUrl);
    } catch (e) {
      toastRef.current = toast.error(t('AppSumo.TokenNotValid'));
      navigate(navigateUrl);
    }
  }, [searchParams, navigate, t, navigateUrl, setUser]);

  const handlePost = useCallback(
    async (values) => fetchLogin(values, setUser, t, navigate, navigateUrl),
    [setUser, t, navigate, navigateUrl],
  );

  return (
    <>
      <Helmet>
        <title>{t('Global.WelcomeToFlotiq')}</title>
      </Helmet>
      <Authentication
        loginLayout={true}
        headerText={t('Global.WelcomeToFlotiq')}
        paragraphText={
          <>
            {t('Global.NewToFlotiq')}
            <Link to="/register" className="text-blue">
              <span> {t('Global.SignUp')}</span>
            </Link>
          </>
        }
        logoLink={process.env.REACT_APP_FLOTIQ_PAGE_URL}
        additionalClasses={twMerge(darkMode && 'dark:bg-gray-900')}
        {...getTestProps(testId, 'authentication', 'testId')}
      >
        <LoginForm
          buttonText={activated === 'true' ? t('Activation.True') : ''}
          error={error}
          onSubmit={handlePost}
          {...getTestProps(testId, 'form', 'testId')}
        />
      </Authentication>
    </>
  );
};

export default Login;

Login.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Login.defaultProps = {
  testId: '',
};
