import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Combobox, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Hooks
import useDebounceCallback from '../../hooks/useDebounceCallback';

// :: Components
import Loader from '../../components/Loader/Loader';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';

// :: Images
import { CaretDownIcon, CloseIcon, CheckmarkIcon } from '../../images/shapes';

const checkIfIncludes = (string, query) => {
  return string
    .toLowerCase()
    .replace(/\s+/g, '')
    .includes(query.toLowerCase().replace(/\s+/g, ''));
};

const SelectedOptions = ({
  placeholder,
  multiple,
  selectedOptions,
  onRemove,
  disabled,
  ignoreNotFound,
  testId,
  isSearch,
  maxVisibleValues,
  maxVisibleValuesText,
  selectedForDelete,
}) => {
  const { t } = useTranslation();
  const placeholderColor = disabled
    ? 'text-zinc-600'
    : 'text-slate-400 font-light';

  if (!multiple || !selectedOptions?.length)
    return (
      <div
        className={twMerge(
          !selectedOptions?.label && placeholderColor,
          !ignoreNotFound && selectedOptions?.notFound && 'text-red',
        )}
      >
        {selectedOptions?.label || placeholder}
      </div>
    );

  return selectedOptions.map((selectedOption, idx) => {
    if (maxVisibleValues && idx >= maxVisibleValues)
      return idx === selectedOptions.length - 1
        ? maxVisibleValuesText ||
            t('Global.DropdownMore', {
              value: selectedOptions.length - maxVisibleValues,
            })
        : null;

    if (selectedOption.ignoreCheckmark) return '';

    return (
      <div
        key={selectedOption.value}
        className={twMerge(
          'inline-flex items-center px-2',
          'bg-blue-300 rounded cursor-default',
          !ignoreNotFound && selectedOption.notFound && 'bg-red text-white',
          'max-w-full shrink-0 min-w-0',
          maxVisibleValues === 1 && 'max-w-[70%]',
          selectedForDelete === selectedOption.value && 'border border-red',
        )}
      >
        <span
          className={twMerge(
            'overflow-hidden py-px dark:text-slate-950 truncate',
            isSearch && 'min-h-[14px] min-w-[14px]',
          )}
          title={selectedOption.label}
        >
          {selectedOption.label || selectedOption.value}
        </span>

        {!disabled && (
          <button
            className={
              'flex items-center !bg-transparent !p-1 !border-none ml-1 hover:!bg-blue rounded-full group'
            }
            onClick={(e) => {
              e.stopPropagation();
              onRemove(selectedOption);
            }}
            type="button"
            {...getTestProps(testId, `${selectedOption.value}-remove`)}
          >
            <CloseIcon
              className="w-2 h-2 text-black group-hover:text-white
            transition duration-200 ease-in-out dark:text-slate-950 dark:group-hover:text-white"
            />
          </button>
        )}
      </div>
    );
  });
};

const Dropdown = ({
  options,
  hideSearch,
  multiple,
  value,
  onChange,
  onBlur,
  placeholder,
  label,
  required,
  disabled,
  nullable,
  filterGroups,
  name,
  error,
  helpText,
  renderEmpty,
  emptyOptions,
  filterCallback,
  isDataLoading,
  loadingIcon,
  closeAndClearAfterSelect,
  additionalClasses,
  additionalOptionsClasses,
  additionalDropdownErrorClasses,
  testId,
  additionalDropdownClasses,
  additionalContainerClasses,
  filterCallbackOnEmpty,
  debounceTime,
  ignoreNotFound,
  renderOnEmptyMatch,
  extraOptions,
  maxVisibleValues,
  maxVisibleValuesText,
  placeholderMultiple,
  additionalInputClasses,
  additionalHelpTextClasses,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [query, setQuery] = useState('');
  const [customFilteredOptions, setCustomFilteredOptions] = useState([]);
  const [selectedForDelete, setSelectedForDelete] = useState(null);

  const [isLoading, setIsLoading] = useState(isDataLoading);
  const inputRef = useRef();
  const buttonRef = useRef();

  useEffect(() => setCurrentValue(value), [value]);
  useEffect(() => setIsLoading(isDataLoading), [isDataLoading]);

  const currentOptions = useMemo(() => {
    const newOptions = [...options];
    let val = currentValue;
    if (!Array.isArray(val)) val = [currentValue];
    val.forEach((element) => {
      const optionsVal = newOptions.find((el) => el.value === element);
      const customFiltersVal = customFilteredOptions.find(
        (el) => el.value === element,
      );
      const extraOptionsVal = extraOptions.find((el) => el.value === element);
      if (!optionsVal && customFiltersVal) {
        newOptions.push(customFiltersVal);
      }
      if (!optionsVal && !customFiltersVal && extraOptionsVal) {
        newOptions.push(extraOptionsVal);
      }
    });
    return newOptions;
  }, [options, currentValue, customFilteredOptions, extraOptions]);

  const selectedOptions = useMemo(() => {
    let newVal = currentValue;
    if (!multiple) {
      if (Array.isArray(currentValue)) {
        newVal = currentValue[0];
        setCurrentValue(newVal);
      }
      const optionFound = currentOptions.find(
        (option) => option.value === newVal,
      );
      if (optionFound) return optionFound;
      return newVal
        ? {
            value: newVal,
            label: newVal,
            notFound: true,
          }
        : null;
    }
    if (!Array.isArray(currentValue)) {
      newVal = currentValue ? [currentValue] : [];
      setCurrentValue(newVal);
    }
    return newVal
      .map(
        (val) =>
          currentOptions.find((option) => option.value === val) || {
            value: val,
            label: val,
            notFound: true,
          },
      )
      .filter((option) => !!option);
  }, [currentValue, multiple, currentOptions]);

  const handleInputBlur = useCallback(() => {
    setSelectedForDelete(null);
  }, []);

  const handleSearchChange = useDebounceCallback((event) => {
    setQuery(event.target.value);
  }, debounceTime);

  const handleChange = useCallback(
    (val) => {
      if (!multiple) {
        setTimeout(() => {
          inputRef.current?.blur();
        });
      }
      setCurrentValue(val);
      if (onChange)
        onChange(
          {
            target: { value: val, name },
            option:
              customFilteredOptions[
                customFilteredOptions.findIndex((el) => el.value === val)
              ] || options[options.findIndex((el) => el.value === val)],
          },
          val,
        );

      if (multiple) {
        if (inputRef.current) inputRef.current.value = '';
        setQuery('');
      }

      if (closeAndClearAfterSelect && multiple) {
        if (buttonRef.current.dataset?.headlessuiState)
          buttonRef.current.click();
      }
    },
    [
      onChange,
      name,
      closeAndClearAfterSelect,
      multiple,
      customFilteredOptions,
      options,
    ],
  );

  const handleDeleteSelected = useDebounceCallback((event) => {
    if (!multiple) return;
    if (event.key === 'Backspace') {
      if (query === '') {
        if (selectedForDelete === null) {
          setSelectedForDelete(currentValue[currentValue.length - 1]);
          return;
        }

        const newValue = currentValue.filter(
          (val) => val !== selectedForDelete,
        );

        handleChange(newValue);
        setSelectedForDelete(null);
      }
    }
  });

  const handleBlur = useCallback(
    (e) => {
      e.preventDefault();
      onBlur({ target: { name } });
    },
    [onBlur, name],
  );

  const fetchFilterData = useCallback(
    async (query, options, setIsLoading, currentValue) => {
      if (!filterCallback || !query) return;
      const filtered = await filterCallback(
        query,
        options,
        setIsLoading,
        currentValue,
      );
      setCustomFilteredOptions(filtered);
    },
    [filterCallback],
  );

  useEffect(() => {
    fetchFilterData(query, options, setIsLoading, currentValue);
  }, [fetchFilterData, options, query, filterCallback, currentValue]);

  const filteredOptions = useMemo(() => {
    const strictFilter =
      query === ''
        ? currentOptions
        : [...currentOptions, ...customFilteredOptions].filter((option) => {
            return option.label === query;
          });

    if (filterCallback && query)
      return { filterResult: customFilteredOptions, strictFilter };

    const filterResult =
      query === ''
        ? currentOptions
        : currentOptions.filter((option) => {
            const optionSearch = option.searchString
              ? option.searchString
              : option.label;
            return filterGroups
              ? checkIfIncludes(optionSearch, query) ||
                  checkIfIncludes(option.group || '', query)
              : checkIfIncludes(optionSearch, query);
          });

    return { filterResult, strictFilter };
  }, [
    currentOptions,
    query,
    filterGroups,
    filterCallback,
    customFilteredOptions,
  ]);

  const groupedOptions = useMemo(
    () =>
      filteredOptions?.filterResult?.reduce((acc, option) => {
        const groupName = option.group || '';
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(option);
        return acc;
      }, {}),
    [filteredOptions],
  );

  const handleRemoveItem = useCallback(
    (removed) => {
      const newValue = currentValue.filter((val) => val !== removed.value);
      handleChange(newValue);
    },
    [handleChange, currentValue],
  );

  const handleClear = useCallback(
    (e) => {
      e.stopPropagation();
      const newValue = multiple ? [] : null;
      handleChange(newValue);
    },
    [handleChange, multiple],
  );

  const renderWithoutOptions = useMemo(() => {
    if (isLoading)
      return loadingIcon ? (
        loadingIcon
      ) : (
        <div
          className="flex items-center justify-center w-full h-full p-4"
          {...getTestProps(testId, 'loading')}
        >
          <Loader size="small" type="spinner-grid" />
        </div>
      );

    if (filteredOptions?.filterResult?.length === 0) {
      if (renderEmpty && query !== '') return renderEmpty?.(query);
      if (emptyOptions && query === '') return emptyOptions;
    }
    return null;
  }, [
    isLoading,
    filteredOptions,
    query,
    renderEmpty,
    emptyOptions,
    loadingIcon,
    testId,
  ]);

  const handleAddOnEmpty = useMemo(() => {
    if (filteredOptions?.strictFilter?.length === 0) {
      return renderOnEmptyMatch?.(query);
    }
    return null;
  }, [filteredOptions, query, renderOnEmptyMatch]);

  const renderLabelAndInput = useMemo(
    () => (
      <div
        className={twMerge(
          'min-w-6 max-w-full flex gap-y-1 gap-x-1 dark:text-white',
          (!maxVisibleValues || maxVisibleValues > 1) && 'flex-wrap',
        )}
        {...getTestProps(testId, 'selected-options')}
      >
        {((multiple && selectedOptions.length) || hideSearch || disabled) && (
          <SelectedOptions
            placeholder={placeholder}
            value={currentValue}
            multiple={multiple}
            selectedOptions={selectedOptions}
            onRemove={handleRemoveItem}
            disabled={disabled}
            testId={testId}
            isSearch={!hideSearch}
            ignoreNotFound={ignoreNotFound}
            maxVisibleValues={maxVisibleValues}
            maxVisibleValuesText={maxVisibleValuesText}
            selectedForDelete={selectedForDelete}
          />
        )}
        {!hideSearch && !disabled && (
          <>
            <span
              className={twMerge(
                'h-2 w-2 rounded-full absolute',
                selectedOptions?.circleColor || 'hidden',
              )}
              {...getTestProps(testId, 'circle-color')}
            />

            <Combobox.Input
              ref={inputRef}
              className={twMerge(
                'text-ellipsis text-base grow min-w-3 w-fit max-w-full focus:outline-none',
                'placeholder:text-slate-400 placeholder:font-light dark:bg-transparent dark:text-white',
                !selectedOptions?.length && 'w-full',
                selectedOptions?.circleColor && 'pl-4',
                !multiple &&
                  selectedOptions?.notFound &&
                  query === '' &&
                  !ignoreNotFound &&
                  'text-red',
                additionalInputClasses,
              )}
              displayValue={() => {
                if (!multiple)
                  return typeof selectedOptions?.label !== 'string'
                    ? selectedOptions?.searchString
                    : selectedOptions?.label || selectedOptions?.value;
                return '';
              }}
              onChange={handleSearchChange}
              onKeyDown={handleDeleteSelected}
              onBlur={handleInputBlur}
              autoComplete={'off'}
              placeholder={
                !multiple || !selectedOptions.length
                  ? placeholder
                  : placeholderMultiple
              }
              {...getTestProps(testId, 'input')}
            />
          </>
        )}
      </div>
    ),
    [
      maxVisibleValues,
      testId,
      multiple,
      selectedOptions,
      hideSearch,
      disabled,
      placeholder,
      currentValue,
      handleRemoveItem,
      ignoreNotFound,
      maxVisibleValuesText,
      selectedForDelete,
      query,
      additionalInputClasses,
      handleSearchChange,
      handleDeleteSelected,
      handleInputBlur,
      placeholderMultiple,
    ],
  );

  const handleAfterEnter = () => {
    if (filterCallbackOnEmpty && !query) {
      setQuery(`_initOnEmpty`);
    }
  };

  return (
    <Combobox
      value={currentValue}
      onChange={handleChange}
      onBlur={handleBlur}
      multiple={multiple}
      disabled={disabled}
      name={name}
      nullable={nullable}
    >
      {({ open }) => (
        <div className={twMerge(additionalClasses)}>
          <div className="relative w-full flex flex-col">
            {label && (
              <label
                htmlFor={name}
                className={twMerge(
                  'text-sm text-slate-400 dark:text-gray-200 mb-1',
                )}
                {...getTestProps(testId, 'label')}
              >
                {label}
                {required && <RequiredTemplate testId={testId} />}
              </label>
            )}
            <div
              className={twMerge(
                'flex relative w-full cursor-default bg-white focus-within:border-blue px-4',
                'overflow-hidden rounded-lg text-left border border-slate-200 dark:border-slate-700 items-center',
                'dark:bg-transparent py-2.5',
                disabled && 'bg-gray cursor-not-allowed dark:bg-gray-900',
                error && 'border-red',
                multiple ? 'min-h-[48px]' : 'h-12',
                additionalContainerClasses,
              )}
              {...getTestProps(testId, 'container')}
            >
              <Combobox.Button
                ref={buttonRef}
                as="div"
                className={twMerge(
                  'grid grid-cols-[1fr_fit-content(100px)] gap-0.5 md:gap-2',
                  'w-full items-center justify-between max-h-full',
                )}
              >
                {renderLabelAndInput}
                <div className="inline-flex w-full text-zinc-600 dark:text-white">
                  {((multiple && selectedOptions.length > 0) ||
                    (!multiple && selectedOptions && nullable)) &&
                    !disabled && (
                      <CloseIcon
                        className={twMerge(
                          'cursor-pointer w-3 mr-2 hover:opacity-30',
                        )}
                        onClick={(e) => handleClear(e)}
                        {...getTestProps(testId, 'clear-all')}
                      />
                    )}
                  <CaretDownIcon
                    className={twMerge(
                      'w-3.5',
                      open ? 'rotate-180 transform' : '',
                      disabled ? 'cursor-not-allowed' : 'cursor-pointer',
                    )}
                    {...getTestProps(testId, 'arrow-icon')}
                  />
                </div>
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
              afterEnter={handleAfterEnter}
            >
              <Combobox.Options
                className={twMerge(
                  'absolute top-full mt-2 max-h-40 w-full rounded-md overflow-auto overflow-overlay',
                  'scrollbar-sm bg-white dark:bg-gray-900 dark:border dark:border-gray-800 text-base shadow-md',
                  'focus:outline-none z-20',
                  additionalDropdownClasses,
                )}
                {...getTestProps(testId, 'options')}
              >
                {renderWithoutOptions
                  ? renderWithoutOptions
                  : Object.keys(groupedOptions).map((groupName) => (
                      <Fragment key={groupName}>
                        {groupName && (
                          <li
                            className={twMerge(
                              'italic font-bold py-2 pl-4 pr-9 dark:text-white',
                            )}
                            key={groupName}
                            {...getTestProps(testId, `${groupName}-group`)}
                          >
                            {groupName}
                          </li>
                        )}
                        <>
                          {handleAddOnEmpty}
                          {groupedOptions[groupName].map((option) => (
                            <Combobox.Option
                              key={option.value}
                              className={({ active }) =>
                                twMerge(
                                  active && 'bg-blue-300 dark:bg-transparent',
                                  'cursor-default select-none py-2 pl-4',
                                  groupName && 'pl-6',
                                  option.disabled
                                    ? 'bg-gray cursor-not-allowed'
                                    : 'hover:cursor-pointer hover:dark:bg-gray-700',
                                  additionalOptionsClasses,
                                  option.disabled &&
                                    option.disabledAdditionClass,
                                )
                              }
                              value={option.value}
                              disabled={option.disabled}
                              {...getTestProps(
                                testId,
                                `${option.value}-option`,
                              )}
                            >
                              {({ selected }) => (
                                <div className="flex flex-row items-center justify-between">
                                  <span className="flex items-center truncate min-h-[21px] dark:text-white w-full">
                                    <span
                                      className={twMerge(
                                        'h-2 w-2 mr-2 rounded-full',
                                        option.circleColor || 'hidden',
                                      )}
                                    />
                                    {option.optionLabel ||
                                      option.label ||
                                      option.value}
                                  </span>
                                  <span
                                    className={twMerge(
                                      'text-blue pr-1 md:pr-5',
                                    )}
                                  >
                                    {selected && !option.ignoreCheckmark && (
                                      <CheckmarkIcon
                                        className="w-3.5 text-blue"
                                        {...getTestProps(
                                          testId,
                                          `${option.value}-selected`,
                                        )}
                                      />
                                    )}
                                  </span>
                                </div>
                              )}
                            </Combobox.Option>
                          ))}
                        </>
                      </Fragment>
                    ))}
              </Combobox.Options>
            </Transition>
          </div>
          <HelpErrorTextsTemplate
            helpText={helpText}
            error={error}
            additionalErrorClasses={additionalDropdownErrorClasses}
            additionalHelpClasses={additionalHelpTextClasses}
            testId={testId}
          />
        </div>
      )}
    </Combobox>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  /**
   * Theme
   */
  theme: PropTypes.oneOf(['light', 'dark']),
  /**
   * Options to select
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      searchString: (props, propName, componentName) => {
        if (props[propName] && typeof props[propName] !== 'string')
          return new Error(
            `Invalid prop '${propName}' '${props[propName]}' supplied to '${componentName}'`,
          );
        if (typeof props.label !== 'string' && !props[propName])
          return new Error(
            `Prop '${propName}' type '${props[propName]} supplied to '${componentName}' is required ` +
              `if label is not string'`,
          );
      },
      value: PropTypes.any,
      optionLabel: PropTypes.node,
      group: PropTypes.string,
      disabled: PropTypes.bool,
      ignoreCheckmark: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * If search input should be hidden
   */
  hideSearch: PropTypes.bool,
  /**
   * If multiple options can be selected
   */
  multiple: PropTypes.bool,
  /**
   * Selected value
   */
  value: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.any)]),
  /**
   * On selected value change
   */
  onChange: PropTypes.func,
  /**
   * Dropdown on blur handler
   */
  onBlur: PropTypes.func,
  /**
   * Placeholder for no value selected
   */
  placeholder: PropTypes.string,
  /**
   * Help text under dropdown
   */
  helpText: PropTypes.any,
  /**
   * Label above dropdown
   */
  label: PropTypes.node,
  /**
   * If dropdown is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If nullable value is allowed
   */
  nullable: PropTypes.bool,
  /**
   * If filtering by groups is allowed
   */
  filterGroups: PropTypes.bool,
  /**
   * Name for dropdown
   */
  name: PropTypes.string,
  /**
   * Error text under dropdown
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Render empty state callback
   */
  renderEmpty: PropTypes.func,
  /**
   * Empty state when there is no options
   */
  emptyOptions: PropTypes.node,
  /**
   * Filter callback
   */
  filterCallback: PropTypes.func,
  /**
   * If data are loading
   */
  isDataLoading: PropTypes.bool,
  /**
   * Loading icon
   */
  loadingIcon: PropTypes.node,
  /**
   * If dropdown should be closed and input cleard after selecting option
   */
  closeAndClearAfterSelect: PropTypes.bool,
  /**
   * Additional classes for dropdown options
   */
  additionalOptionsClasses: PropTypes.string,
  /**
   * Dropdown additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Dropdown additional classes
   */
  additionalDropdownErrorClasses: PropTypes.string,
  /**
   * Test id for dropdown
   */
  testId: PropTypes.string,
  /**
   * Dropdown additional dropdown classes
   */
  additionalDropdownClasses: PropTypes.string,
  /**
   * Dropdown additional container classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Dropdown additional input classes
   */
  additionalInputClasses: PropTypes.string,
  /**
   * Dropdown delay update change value on change input
   */
  debounceTime: PropTypes.number,
  /**
   * Disable red-styled label on unfounded element
   */
  ignoreNotFound: PropTypes.bool,
  /**
   * Render add element option when there is not exact match while searching
   */
  renderOnEmptyMatch: PropTypes.func,
  /**
   * Extra options for dropdown
   */
  extraOptions: PropTypes.array,
  /**
   * Placeholder for multiple when more than one selected
   */
  placeholderMultiple: PropTypes.string,
  /**
   * If dropdown is multiple and should show limited selected options
   */
  maxVisibleValues: PropTypes.number,
  /**
   * If max visible values is provided and the default text should be overriden
   */
  maxVisibleValuesText: PropTypes.string,
  /**
   * Additional help text classes
   */
  additionalHelpTextClasses: PropTypes.string,
};

Dropdown.defaultProps = {
  theme: 'light',
  hideSearch: false,
  multiple: false,
  value: '',
  placeholder: '',
  label: '',
  disabled: false,
  filterGroups: false,
  testId: '',
  name: '',
  error: '',
  helpText: '',
  nullable: false,
  isDataLoading: false,
  loadingIcon: '',
  emptyOptions: '',
  closeAndClearAfterSelect: false,
  additionalOptionsClasses: '',
  additionalClasses: '',
  additionalDropdownErrorClasses: '',
  additionalInputClasses: '',
  onBlur: /* istanbul ignore next */ () => null,
  filterCallbackOnEmpty: false,
  additionalDropdownClasses: '',
  additionalContainerClasses: '',
  additionalHelpTextClasses: '',
  debounceTime: 0,
  ignoreNotFound: false,
  renderOnEmptyMatch: null,
  extraOptions: [],
  placeholderMultiple: '',
};
