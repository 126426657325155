import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StatusPill from '../../StatusPill/StatusPill';
import { twMerge } from 'tailwind-merge';
import { capitalizeText } from '../../../lib/helpers';

const VersionPill = ({ workflowState, orderClasses }) => {
  const { t, i18n } = useTranslation();

  return (
    <StatusPill
      containerClasses={twMerge(
        'h-[20px] py-0 px-[10px] rounded-md inline-flex items-center justify-center w-max',
        'text-xs font-normal',
        orderClasses,
      )}
      color={workflowState}
      status={
        i18n.exists(`ObjectStatus.VersionStatus.${workflowState}`)
          ? t(`ObjectStatus.VersionStatus.${workflowState}`)
          : capitalizeText(workflowState)
      }
    />
  );
};

export default VersionPill;

VersionPill.propTypes = {
  /**
   * Content object worfklow status
   */
  workflowState: PropTypes.string,
  /**
   * Classes for managing pill order
   */
  orderClasses: PropTypes.string,
};

VersionPill.defaultProps = {
  workflowState: '',
  orderClasses: '',
};
