import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Maps from '../Maps/Maps';
import Input from '../Input/Input';
import { useTranslation } from 'react-i18next';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import { twMerge } from 'tailwind-merge';

const coordinateInputs = [
  {
    key: 'lat',
    transKey: 'Latitude',
  },
  {
    key: 'lon',
    transKey: 'Longitude',
  },
];

const CoordinateInput = ({
  coordinate,
  name,
  value,
  onChange,
  markerPosition,
  setCenter,
  ...props
}) => {
  const { t } = useTranslation();
  const fieldName = `${name}.${coordinate.key}`;

  const handleChange = useCallback(
    (e) => {
      if (e.target.value === '') onChange(null, name, coordinate.key);
      else onChange(+e.target.value, name, coordinate.key);

      const newCenter = { ...markerPosition };
      if (coordinate.key === 'lon') newCenter.lng = +e.target.value;
      else newCenter.lat = +e.target.value;
      setCenter(newCenter);
    },
    [coordinate.key, markerPosition, name, onChange, setCenter],
  );

  return (
    <Input
      name={fieldName}
      value={value[coordinate.key]}
      onChange={handleChange}
      type="number"
      step="0.001"
      label={t(`ContentForm.${coordinate.transKey}`)}
      additionalClasses="max-w-2xl"
      {...props}
    />
  );
};

const GeoInput = ({
  name,
  label,
  required,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  helpText,
  additionalClasses,
  testId,
}) => {
  const markerPosition = useMemo(
    () => ({
      lat: value.lat || 0,
      lng: value.lon || 0,
    }),
    [value],
  );

  const [center, setCenter] = useState(markerPosition);

  const onMarkerPositionChange = useCallback(
    (position) => {
      onChange(position, name);
      onBlur({ target: { name } });
    },
    [onChange, onBlur, name],
  );

  return (
    <div
      className={twMerge(
        'w-full',
        'flex',
        'flex-col',
        'relative',
        additionalClasses,
      )}
    >
      {label && (
        <label className="text-sm text-slate-400 dark:text-gray-200 mb-1">
          {label}
          {required && <RequiredTemplate />}
        </label>
      )}
      <Maps
        center={center}
        markerPosition={markerPosition}
        onMarkerPositionChange={onMarkerPositionChange}
        disabled={disabled}
      />
      <div
        className={twMerge(
          'grid grid-cols-2 gap-2 lg:gap-6 mt-3',
          additionalClasses,
        )}
      >
        {coordinateInputs.map((coordinate) => {
          return (
            <CoordinateInput
              key={coordinate.key}
              coordinate={coordinate}
              name={name}
              value={value}
              onChange={onChange}
              markerPosition={markerPosition}
              setCenter={setCenter}
              onBlur={onBlur}
              error={error?.[coordinate.key]}
              disabled={disabled}
              required={required}
              testId={testId}
            />
          );
        })}
      </div>
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={error?.global}
        testId={testId ? `${testId}-global` : ''}
      />
    </div>
  );
};

export default GeoInput;

GeoInput.propTypes = {
  /**
   * Geo field name
   */
  name: PropTypes.string,
  /**
   * Field label above maps
   */
  label: PropTypes.node,
  /**
   * If field is required
   */
  required: PropTypes.bool,
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Geo field value
   */
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  ]),
  /**
   * On change callback
   * (when calling with null value property name is only coordinate ex. lat,
   * whith different value property name is whole field, ex. geo.lat)
   */
  onChange: PropTypes.func,
  /**
   * On blur callback
   */
  onBlur: PropTypes.func,
  /**
   * Error text under lat lon inputs
   */
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
    PropTypes.shape({
      lat: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      lon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    }),
  ]),
  /**
   * Help text under lat lon inputs
   */
  helpText: PropTypes.node,
  /**
   * Additional CSS classes for geo input
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for fields
   */
  testId: PropTypes.string,
};

GeoInput.defaultProps = {
  name: '',
  label: '',
  required: false,
  disabled: false,
  value: {},
  onChange: /* istanbul ignore next */ () => null,
  onBlur: /* istanbul ignore next */ () => null,
  error: null,
  helpText: '',
  additionalClasses: '',
  testId: '',
};
