import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Avatar from '../Avatar/Avatar';
import DateContainer from '../DateContainer/DateContainer';
import Panel from '../Panel/Panel';
import { getTestProps } from '../../lib/helpers';
import {
  defaultButtonsProps,
  defaultTextRender,
  useModals,
} from '../../contexts/ModalContext';
import { useCallback, useMemo } from 'react';
import VersionDiffModalContent from './VersionDiffModalContent/VersionDiffModalContent';
import VersionPill from './VersionPill/VersionPill';
import { ReloadIcon } from '../../images/shapes';

const ContentObjectVersions = ({
  versions,
  onRestore,
  currentChanges,
  contentType,
  isDirty,
  additionalClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const modal = useModals();

  const latestVersion = useMemo(() => versions[0], [versions]);

  const onVersionClick = useCallback(
    async (version) => {
      const oldContentObject = await modal({
        content: (
          <VersionDiffModalContent
            selectedVersion={version}
            latestVersion={isDirty ? null : latestVersion}
            currentChanges={currentChanges}
            contentType={contentType}
            {...getTestProps(testId, 'diff-modal', 'testId')}
          />
        ),
        size: '3xl',
      });

      if (oldContentObject) {
        const restore = await modal({
          title: defaultTextRender.warning(t('Global.Warning')),
          content: t('Versions.RestoreConfirm'),
          buttons: [
            { ...defaultButtonsProps.cancel, label: t('Global.Cancel') },
            {
              key: 'restore',
              label: t('Versions.RestoreVersion'),
              iconImage: <ReloadIcon className="w-3 min-w-3" />,
              iconPosition: 'start',
              result: true,
            },
          ],
        });
        if (restore) {
          onRestore(oldContentObject);
        }
      }
    },
    [
      contentType,
      currentChanges,
      isDirty,
      latestVersion,
      modal,
      onRestore,
      t,
      testId,
    ],
  );

  return (
    <Panel
      title={t('Versions.Versions')}
      isCollapsable={false}
      additionalContainerClasses={twMerge('py-5 px-4', additionalClasses)}
      additionalTitleClasses="text-lg"
      additionalChildrenClasses={'pt-2'}
      open
      {...getTestProps(testId, `co-versions-container`, 'testId')}
    >
      <div
        className={twMerge(
          'flex flex-col space-y-4 max-h-[340px] overflow-y-overlay scrollbar-sm',
        )}
      >
        {versions?.map((version) => (
          <div
            key={version.id}
            onClick={() => onVersionClick(version)}
            {...getTestProps(testId, `version-select-option-${version.id}`)}
            className={twMerge(
              'inline-flex items-center justify-between mr-0.5',
              'cursor-pointer group',
            )}
          >
            <div className="inline-flex items-end space-x-3 w-full">
              <Avatar
                userInitials={
                  version.editor
                    ? version.editor?.firstName[0] + version.editor?.lastName[0]
                    : ''
                }
                additionalClasses="shrink-0 !cursor-pointer text-lg my-2.5"
                testId={testId}
              />
              <div
                className={twMerge(
                  'flex flex-col xs:flex-row xl:flex-col 2xl:flex-row justify-start',
                  'items-start xs:items-center xl:items-start 2xl:items-center',
                  '2xl:items-center gap-3 2xl:gap-6 py-2 px-3 w-full',
                  'group-hover:bg-slate-50 dark:group-hover:bg-gray-900 group-hover:rounded-md ',
                )}
              >
                <VersionPill
                  workflowState={version.internal.workflowState}
                  orderClasses="xs:order-last xl:order-none 2xl:order-last"
                />

                <div className="flex flex-col">
                  <DateContainer
                    date={version.internal.updatedAt}
                    dateFormat={'L, LT'}
                    isBold={false}
                    additionalClasses={'text-slate-400'}
                    testId={testId}
                  />

                  <p
                    className="font-bold dark:text-white"
                    {...getTestProps(testId, 'editor')}
                  >
                    {version.editor
                      ? `${version.editor?.firstName} ${version.editor?.lastName}`
                      : `(${t('Global.DeletedUser')})`}
                  </p>
                  {version.apiKeyName && (
                    <p
                      className="dark:text-white"
                      {...getTestProps(testId, 'api-key-name')}
                    >
                      {version.apiKeyName}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Panel>
  );
};

export default ContentObjectVersions;

ContentObjectVersions.propTypes = {
  /**
   * Content object versions array
   */
  versions: PropTypes.array.isRequired,
  /**
   * On restore version click
   */
  onRestore: PropTypes.func.isRequired,
  /**
   * Content type for object
   */
  contentType: PropTypes.object.isRequired,
  /**
   * Current form changes
   */
  currentChanges: PropTypes.object,
  /**
   * If form is dirty
   */
  isDirty: PropTypes.bool,
  /**
   * Additional css classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for information container
   */
  testId: PropTypes.string,
};

ContentObjectVersions.defaultProps = {
  currentChanges: {},
  isDirty: false,
  additionalClasses: '',
  testId: '',
};
