import PropTypes from 'prop-types';
import toast, { Toaster } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';

// :: Icons
import {
  CheckCircleIcon,
  WarningIcon,
  CloseIcon,
  SpinnerGridIcon,
} from '../../images/shapes';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const Toast = ({ testId }) => (
  <Toaster
    position="top-center"
    containerStyle={{
      top: 15,
    }}
    toastOptions={{
      duration: 3000,
      success: {
        icon: (
          <CheckCircleIcon className="w-6 text-green-300" aria-hidden="true" />
        ),
        className: 'border-green-300',
      },
      error: {
        icon: <WarningIcon className="w-6 text-red" aria-hidden="true" />,
        className: 'border-red',
      },
      loading: {
        icon: <SpinnerGridIcon className="w-6 h-6 animate-spin" />,
        className: 'border-gray',
      },
    }}
  >
    {(t) => (
      <div
        className={twMerge(
          `z-50 max-w-sm w-full bg-white dark:bg-gray-900 shadow-xl rounded-lg`,
          `pointer-events-auto border ${t.className} overflow-hidden`,
        )}
        style={t.style}
      >
        <div className="p-3">
          <div className="flex items-center">
            <div className="flex-shrink-0">{t.icon}</div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-base font-semibold text-black dark:text-white">
                {t.message}
              </p>
              <p className="mt-1 text-sm text-black dark:text-white">
                {t.subMessage}
              </p>
            </div>
            <div className="ml-4 flex-shrink-0 flex self-start">
              <button
                type="button"
                className="inline-flex text-black focus:outline-none"
                onClick={() => toast.remove(t.id)}
                {...getTestProps(testId, 'close')}
              >
                <span className="sr-only">Close</span>
                <CloseIcon className="w-3 dark:text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    )}
  </Toaster>
);

export default Toast;

Toast.propTypes = {
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Toast.defaultProps = {
  testId: '',
};
