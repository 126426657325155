import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Components
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';

// :: Lib helpers
import { getTestProps } from '../../../lib/helpers';

// :: Images
import { TrashIcon } from '../../../images/shapes';

const SectionHeaders = ({
  headers,
  onRemove,
  onAdd,
  onChange,
  errors,
  disabled,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <div {...getTestProps(testId, `container`)}>
      {headers?.map((el, index) => (
        <div
          key={`hid-${el.name}-${index}`}
          className={twMerge(
            'relative p-4',
            'border border-solid border-gray dark:border-slate-800 rounded-lg mb-4',
          )}
          {...getTestProps(testId, `wrap-${index}`)}
        >
          <Button
            type="button"
            buttonSize="sm"
            buttonColor="borderless"
            onClick={() => onRemove(el.id)}
            iconImage={<TrashIcon className="w-3" />}
            additionalClasses={twMerge(
              'absolute right-2 top-2 group p-0',
              'm-0 w-7 h-7 group-hover:opacity-50 z-10',
            )}
            additionalIconClasses={'hover:opacity-50 selection-none'}
            disabled={disabled}
            {...getTestProps(testId, `headers-delete-${index}`, 'testId')}
          />

          <Input
            name={`headers[${index}].header_name`}
            type="text"
            label={t('Webhooks.HeaderName')}
            value={el.header_name}
            onChange={onChange}
            error={errors?.[index] ? errors?.[index].header_name : null}
            additionalClasses={'mb-4'}
            disabled={disabled}
            required
            {...getTestProps(testId, `headers-name-${index}`, 'testId')}
          />

          <Input
            name={`headers[${index}].header_value`}
            type="text"
            label={t('Webhooks.HeaderValue')}
            value={el.header_value}
            onChange={onChange}
            error={errors?.[index] ? errors?.[index].header_value : null}
            disabled={disabled}
            required
            {...getTestProps(testId, `headers-value-${index}`, 'testId')}
          />
        </div>
      ))}

      <Button
        buttonSize="sm"
        additionalClasses="w-max mb-4"
        additionalChildrenClasses="flex flex-row items-center"
        onClick={onAdd}
        disabled={disabled}
        {...getTestProps(testId, `button-add`, 'testId')}
      >
        {t('Webhooks.AddHeader')}
      </Button>
    </div>
  );
};

export default SectionHeaders;

SectionHeaders.propTypes = {
  /**
   * Headers
   */
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      header_name: PropTypes.string,
      header_value: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  /**
   * On remove header handler
   */
  onRemove: PropTypes.func,
  /**
   * Handler on add button
   */
  onAdd: PropTypes.func,
  /**
   * On change value handler
   */
  onChange: PropTypes.func,
  /**
   * Error text
   */
  errors: PropTypes.any,
  /**
   * Is form disabled
   */
  disabled: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

SectionHeaders.defaultProps = {
  disabled: false,
  testId: '',
};
