import { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Hoc
import Authentication from '../../hoc/Authentication/Authentication';

// :: Form
import ChangePasswordRequestForm from '../../form/ChangePasswordRequestForm/ChangePasswordRequestForm';

// :: Lib
import { changePasswordRequest } from '../../lib/flotiq-client/api-helpers';
import { getTestProps } from '../../lib/helpers';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const ChangePasswordRequest = ({ testId }) => {
  const { t } = useTranslation();
  const [darkMode] = useDarkMode();

  const handlePasswordChangeRequest = useCallback(
    async (requestOptions) => {
      return changePasswordRequest(requestOptions, t);
    },
    [t],
  );

  return (
    <>
      <Helmet>
        <title>{t('Form.FormChangePasswordRequestTitle')}</title>
      </Helmet>

      <Authentication
        headerText={t('Form.FormChangePasswordRequestHeader')}
        paragraphText={t('Form.FormChangePasswordRequestEnterEmailHowToReset')}
        logoLink={process.env.REACT_APP_FLOTIQ_PAGE_URL}
        additionalClasses={twMerge(darkMode && 'dark:bg-gray-900')}
        {...getTestProps(testId, 'authentication', 'testId')}
      >
        <ChangePasswordRequestForm
          onSubmit={handlePasswordChangeRequest}
          {...getTestProps(testId, 'form', 'testId')}
        />
      </Authentication>
    </>
  );
};

export default ChangePasswordRequest;

ChangePasswordRequest.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

ChangePasswordRequest.defaultProps = {
  testId: '',
};
