export const eventAttributes = [
  'onclick',
  'ondblclick',
  'onmousedown',
  'onmouseup',
  'onmousemove',
  'onmouseenter',
  'onmouseleave',
  'onmouseover',
  'onmouseout',
  'oncontextmenu',

  'onkeydown',
  'onkeypress',
  'onkeyup',

  'oninput',
  'onchange',
  'onsubmit',
  'onreset',
  'onfocus',
  'onblur',
  'onfocusin',
  'onfocusout',

  'oncopy',
  'oncut',
  'onpaste',

  'ondrag',
  'ondragstart',
  'ondragend',
  'ondragenter',
  'ondragleave',
  'ondragover',
  'ondrop',

  'onload',
  'onunload',
  'onbeforeunload',
  'onresize',
  'onscroll',
  'onwheel',
  'onerror',

  'onabort',
  'oncanplay',
  'oncanplaythrough',
  'ondurationchange',
  'onended',
  'onloadeddata',
  'onloadedmetadata',
  'onpause',
  'onplay',
  'onplaying',
  'onprogress',
  'onratechange',
  'onseeked',
  'onseeking',
  'onstalled',
  'onsuspend',
  'ontimeupdate',
  'onvolumechange',
  'onwaiting',

  'ontoggle',
  'onshow',
  'onslotchange',

  'onanimationstart',
  'onanimationend',
  'onanimationiteration',

  'ontransitionstart',
  'ontransitionend',
  'ontransitionrun',

  'onpointerdown',
  'onpointerup',
  'onpointermove',
  'onpointerover',
  'onpointerout',
  'onpointerenter',
  'onpointerleave',
  'onpointercancel',
  'ongotpointercapture',
  'onlostpointercapture',

  'ontouchstart',
  'ontouchend',
  'ontouchmove',
  'ontouchcancel',
];

export default eventAttributes;
