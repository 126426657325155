import PropTypes from 'prop-types';
import deepEqual from 'deep-equal';
import { getIn } from 'formik';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import CtoCustomField from '../../CtoCustomField/CtoCustomField';
import ContentObjectForm from '../../../form/ContentObjectForm/ContentObjectForm';

const VersionContentObjectForm = ({
  oldContentObject,
  currentContentObject,
  contentType,
  isCurrent,
  selected,
}) => {
  const { t } = useTranslation();

  const renderFormField = useCallback(
    (key, props, schema, isRequired) => {
      const oldValue = getIn(oldContentObject, key);
      const newValue = getIn(currentContentObject, key);
      const isSame = deepEqual(oldValue, newValue);

      const helpText = (
        <>
          {props.helpText}
          <span
            className={twMerge(
              'absolute right-0 top-0 text-base bg-white dark:bg-gray-900 rounded-sm p-1',
              !isSame
                ? 'text-red-600 dark:text-red-400'
                : 'text-lime-700 dark:text-lime-200',
              isCurrent && 'md:hidden',
            )}
          >
            {isSame ? t('Versions.WithoutChanges') : t('Versions.SomeChanges')}
          </span>
        </>
      );

      return (
        <CtoCustomField
          key={key}
          name={key}
          properties={props}
          schema={schema}
          isRequired={isRequired}
          helpText={helpText}
          disabled={isSame}
          additionalClasses="pointer-events-none"
        />
      );
    },
    [currentContentObject, isCurrent, oldContentObject, t],
  );

  const contentObject = useMemo(
    () => (isCurrent ? currentContentObject : oldContentObject),
    [currentContentObject, isCurrent, oldContentObject],
  );

  const fieldsCount = useMemo(
    () =>
      Object.values(contentType.metaDefinition?.propertiesConfig || {}).filter(
        (props) => !props.hidden,
      ).length || Object.keys(currentContentObject).length,
    [contentType.metaDefinition?.propertiesConfig, currentContentObject],
  );

  return (
    <div
      className={twMerge('md:grid grid-rows-subgrid', selected && 'hidden')}
      style={{
        gridRow: `span ${fieldsCount + 1}`,
      }}
    >
      <ContentObjectForm
        contentObject={contentObject || {}}
        contentType={contentType}
        disabledBuildInValidation
        hasInitialData
        formUniqueKey={`${
          contentObject?.internal?.latestVersion || 'current'
        }-version-diff`}
        additionalFormClasses="grid row-span-full grid-rows-subgrid cursor-not-allowed min-w-0"
        renderField={renderFormField}
      />
    </div>
  );
};

export default VersionContentObjectForm;

VersionContentObjectForm.propTypes = {
  /**
   * Content type for object
   */
  contentType: PropTypes.object.isRequired,
  /**
   * Old version data
   */
  oldContentObject: PropTypes.object,
  /**
   * Current verison data
   */
  currentContentObject: PropTypes.object,
  /**
   * If form data is current content object
   */
  isCurrent: PropTypes.bool,
  /**
   * If tab with the form is selected
   */
  selected: PropTypes.bool,
};

VersionContentObjectForm.defaultProps = {
  oldContentObject: {},
  currentContentObject: {},
  isCurrent: false,
  selected: false,
};
