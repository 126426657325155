import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import Button from '../Button/Button';
import { twMerge } from 'tailwind-merge';
import { getDefaultValueForType, getTestProps } from '../../lib/helpers';
import { ArrowUpIcon, DeleteIcon } from '../../images/shapes';
import { useModals } from '../../contexts/ModalContext';
import CtoCustomField from '../CtoCustomField/CtoCustomField';
import { getIn } from 'formik';
import ContentObjectFormContext from '../../contexts/ContentObjectFormContext';
import { v4 as uuid } from 'uuid';

const swapIds = (array, oldIdx, newIdx) => {
  const oldValue = array[oldIdx];
  const newValue = array[newIdx];
  array[oldIdx] = newValue;
  array[newIdx] = oldValue;
};

const ListItem = ({
  itemsProps,
  currentValue,
  valueKey,
  idx,
  arrayHelpers,
  renderListField,
  handleOrderUp,
  handleOrderDown,
  handleDeleteItem,
  disabled,
  testId,
  label,
}) => (
  <div
    className="relative border bg-white dark:border-slate-800 rounded-lg mb-5
     dark:bg-slate-950"
  >
    <div
      className="w-full px-5 py-4 border-b border-slate-200 dark:border-slate-700 text-slate-400
      dark:text-gray-200"
    >
      {label} #{idx + 1}
    </div>
    <div className="bg-white dark:bg-slate-950 pt-2.5 md:pt-5 pb-2 md:pb-4 rounded-b-lg">
      {itemsProps.order.map((itemName) => {
        const itemKey = `${arrayHelpers.name}[${idx}].${itemName}`;
        return (
          <div className="px-2.5 md:px-5" key={itemKey}>
            {renderListField(itemKey, itemName)}
          </div>
        );
      })}
    </div>
    <div className="absolute right-3 top-5 flex flex-row gap-2 md:gap-3">
      {currentValue.length > 1 && (
        <>
          <Button
            onClick={() => handleOrderUp(idx)}
            iconImage={
              <ArrowUpIcon
                className={twMerge(
                  'h-3 md:h-4 text-slate-400',
                  !(disabled || idx === 0) && 'hover:text-gray-700',
                )}
              />
            }
            buttonColor="borderless"
            noPaddings
            additionalClasses="h-fit w-fit"
            disabled={disabled || idx === 0}
            {...getTestProps(testId, `${valueKey}-up`, 'testId')}
          />
          <Button
            onClick={() => handleOrderDown(idx)}
            iconImage={
              <ArrowUpIcon
                className={twMerge(
                  'h-3 md:h-4 rotate-180 text-slate-400',
                  !(disabled || idx === currentValue.length - 1) &&
                    'hover:text-gray-700',
                )}
              />
            }
            buttonColor="borderless"
            noPaddings
            additionalClasses="h-fit w-fit"
            disabled={disabled || idx === currentValue.length - 1}
            {...getTestProps(testId, `${valueKey}-down`, 'testId')}
          />
        </>
      )}
      <Button
        onClick={() => handleDeleteItem(idx)}
        iconImage={
          <DeleteIcon
            className={twMerge(
              'h-3 md:h-4 text-slate-400',
              !disabled && 'hover:text-gray-700',
            )}
          />
        }
        buttonColor="borderless"
        noPaddings
        additionalClasses="h-fit w-fit"
        disabled={disabled}
        {...getTestProps(testId, `${valueKey}-delete`, 'testId')}
      />
    </div>
  </div>
);

const ListField = ({
  arrayHelpers,
  label,
  required,
  itemsProps,
  itemsSchema,
  error,
  helpText,
  disabled,
  additionalClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const modal = useModals();
  const { isPatchable } = useContext(ContentObjectFormContext);
  const value = useMemo(
    () => getIn(arrayHelpers.form.values, arrayHelpers.name) || [],
    [arrayHelpers.name, arrayHelpers.form],
  );
  const arrayIdsRefs = useRef(value.map(() => uuid()));

  const onBlur = useCallback(
    () =>
      arrayHelpers.form.handleBlur({
        target: { name: arrayHelpers.name },
      }),
    [arrayHelpers.form, arrayHelpers.name],
  );

  useEffect(() => {
    let changed = false;
    const newValue = [...value];
    value.forEach((item, idx) => {
      let newItem;
      if (Array.isArray(item)) {
        changed = true;
        newItem = {};
      } else newItem = { ...item };
      itemsProps.order.forEach((key) => {
        const itemType = itemsSchema.properties[key].type;
        if (newItem[key] == null) {
          changed = true;
          newItem[key] =
            itemsSchema.properties[key].default ||
            getDefaultValueForType(itemsSchema.properties[key].type);
        } else if (itemType === 'object' && Array.isArray(newItem[key])) {
          changed = true;
          newItem[key] = {};
        }
      });
      newValue[idx] = newItem;
    });
    if (changed) {
      arrayHelpers.form.setFieldValue(arrayHelpers.name, newValue);
    }
  }, [value, itemsProps, itemsSchema, arrayHelpers]);

  const addProperty = useCallback(() => {
    const newObject = itemsProps.order.reduce((item, key) => {
      item[key] =
        itemsSchema.properties[key].default ||
        getDefaultValueForType(itemsSchema.properties[key].type);
      return item;
    }, {});
    arrayHelpers.push(newObject);
    onBlur();
    arrayIdsRefs.current.push(uuid());
  }, [itemsSchema, itemsProps, arrayHelpers, onBlur]);

  const renderListField = useCallback(
    (key, itemName) => {
      const itemProps = itemsProps.propertiesConfig[itemName];
      const itemSchema = itemsSchema.properties[itemName];
      const isRequired =
        !isPatchable &&
        itemsSchema.required?.findIndex((key) => key === itemName) > -1;

      return (
        <CtoCustomField
          name={key}
          properties={itemProps}
          schema={itemSchema}
          isRequired={isRequired}
          disabled={disabled}
          additionalClasses="max-w-3xl"
          testId={testId}
        />
      );
    },
    [
      disabled,
      isPatchable,
      itemsProps.propertiesConfig,
      itemsSchema.properties,
      itemsSchema.required,
      testId,
    ],
  );

  const handleOrderUp = useCallback(
    (idx) => {
      arrayHelpers.swap(idx - 1, idx);
      swapIds(arrayIdsRefs.current, idx, idx - 1);
    },
    [arrayHelpers],
  );

  const handleOrderDown = useCallback(
    (idx) => {
      arrayHelpers.swap(idx + 1, idx);
      swapIds(arrayIdsRefs.current, idx, idx + 1);
    },
    [arrayHelpers],
  );

  const handleDeleteItem = useCallback(
    async (idx) => {
      const result = await modal.delete(t('ContentForm.RemoveItem'));
      if (!result) return;
      arrayHelpers.remove(idx);
      arrayIdsRefs.current.splice(idx, 1);
    },
    [arrayHelpers, modal, t],
  );

  return (
    <div
      className={twMerge(
        'bg-white dark:bg-transparent px-0',
        additionalClasses,
      )}
    >
      <label
        className="block w-full text-sm text-slate-400 dark:text-gray-200 pb-2.5"
        {...getTestProps(testId, 'label')}
      >
        {label}
        {required && <RequiredTemplate />}
      </label>
      <div
        className="bg-slate-50 dark:bg-slate-800 border border-slate-200 dark:border-slate-700 p-2.5
        md:p-5 rounded-lg"
      >
        <div className="relative">
          {value && value.length > 0 && (
            <div className="mb-2 space-y-2">
              {value.map((_, idx, currentValue) => {
                const valueKey = `${arrayHelpers.name}[${idx}]`;
                return (
                  <ListItem
                    key={arrayIdsRefs.current[idx]}
                    label={label}
                    itemsProps={itemsProps}
                    currentValue={currentValue}
                    valueKey={valueKey}
                    idx={idx}
                    arrayHelpers={arrayHelpers}
                    renderListField={renderListField}
                    handleOrderUp={handleOrderUp}
                    handleOrderDown={handleOrderDown}
                    handleDeleteItem={handleDeleteItem}
                    disabled={disabled}
                    testId={testId}
                  />
                );
              })}
            </div>
          )}
          <Button
            onClick={addProperty}
            buttonColor="blueBordered"
            buttonSize="xs"
            disabled={disabled}
            {...getTestProps(testId, 'add-item', 'testId')}
          >
            {t('ContentForm.AddItem')}
          </Button>
        </div>
        <HelpErrorTextsTemplate
          helpText={helpText}
          error={typeof error === 'string' ? error : ''}
          testId={testId}
        />
      </div>
    </div>
  );
};

export default ListField;

ListField.propTypes = {
  /**
   * Array helpers from formik array field
   */
  arrayHelpers: PropTypes.object.isRequired,
  /**
   * Field label above items
   */
  label: PropTypes.node,
  /**
   * If field is required
   */
  required: PropTypes.bool,
  /**
   * List items properties
   */
  itemsProps: PropTypes.object,
  /**
   * List items schema
   */
  itemsSchema: PropTypes.object,
  /**
   * Errors for fields
   */
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
    PropTypes.string,
  ]),
  /**
   * Help text under items
   */
  helpText: PropTypes.node,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for field
   */
  testId: PropTypes.string,
};

ListField.defaultProps = {
  label: '',
  required: false,
  itemsProps: {},
  itemsSchema: {},
  error: null,
  helpText: '',
  disabled: false,
  additionalClasses: '',
  testId: '',
};
