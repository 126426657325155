import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useReducer } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import Input from '../../components/Input/Input';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import ValidationToastHandler from '../../components/ValidationToastHandler/ValidationToastHandler';
import { getStripeURL, getTestProps } from '../../lib/helpers';
import Dropdown from '../../components/Dropdown/Dropdown';
import moment from 'moment';
import LinkButton from '../../components/LinkButton/LinkButton';
import Heading from '../../components/Heading/Heading';
import Button from '../../components/Button/Button';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

const getInitialValues = (space) => {
  return {
    name: space?.name || '',
    paymentStatus: space?.paymentStatus || 'non-paid',
    paymentInterval: space?.paymentInterval || null,
    paymentDueDate: space?.paymentDueDate
      ? moment(space?.paymentDueDate).format('YYYY-MM-DDTHH:mm')
      : '',
    subscriptionId: space?.subscriptionId || '',
  };
};

yup.addMethod(yup.number, 'spaceProperty', function (message) {
  return this.test('spaceProperty', message, function (value) {
    return value >= -1 && value !== Infinity;
  });
});

const SpaceFormForm = ({
  space,
  onSubmit,
  disabled,
  navigateOnSave,
  testId,
}) => {
  const { t } = useTranslation();
  const { navigateGrid } = useGridNavigate('spaces', '/spaces-data-preview');

  const [enabledAdminFieldsEdit, toggleEnabledAdminFieldsEdit] = useReducer(
    (enable) => !enable,
    false,
  );

  const validationSchema = yup.object({
    name: yup.string().required(t('Form.FormErrorNotBlank')),
  });

  const handleSubmit = useCallback(
    async (values, formik) => {
      const [[newValues, errors], hasErrors] = await onSubmit(values);
      await formik.setStatus({ ...formik.status, errors });

      if (!hasErrors) {
        if (navigateOnSave.current === true) {
          navigateGrid();
        } else {
          formik.resetForm({
            values: newValues,
          });
        }
      }
    },
    [navigateGrid, navigateOnSave, onSubmit],
  );

  const stripeURL = getStripeURL(
    `/subscriptions/${space?.subscriptionId || ''}`,
  );

  return (
    <Formik
      initialValues={getInitialValues(space)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(formik) => {
        return (
          <form
            id="space-form"
            className="space-y-2 md:space-y-4 p-5 md:pt-10 md:px-12"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <div className="flex flex-col items-left gap-3 w-full">
              <Input
                name="name"
                type="text"
                label={t('Global.Name')}
                disabled={disabled}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.status?.errors?.name || formik.errors.name}
                {...getTestProps(testId, 'space-name', 'testId')}
              />

              <div>
                <Heading level={3} additionalClasses="dark:text-white">
                  {t('Users.PaymentDetails')}
                </Heading>
                <p className="text-sm dark:text-white">
                  {t('Users.PaymentDetailsUpdateAlert')}
                </p>
              </div>
              <Button
                buttonColor={'grayBordered'}
                buttonSize={'xs'}
                onClick={toggleEnabledAdminFieldsEdit}
                disabled={disabled}
                additionalClasses="w-fit mb-2"
                {...getTestProps(testId, 'toggle-payment-edit', 'testId')}
              >
                {t('Users.ToggleEdit')}
              </Button>

              <Dropdown
                name="paymentStatus"
                label={t('Users.PaymentStatus')}
                disabled={!enabledAdminFieldsEdit || disabled}
                value={formik.values.paymentStatus}
                options={[
                  { label: 'paid', value: 'paid' },
                  { label: 'non-paid', value: 'non-paid' },
                  { label: 'trial', value: 'trial' },
                ]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.paymentStatus ||
                  formik.errors.paymentStatus
                }
                {...getTestProps(testId, 'space-paymentStatus', 'testId')}
              />
              <Dropdown
                nullable
                name="paymentInterval"
                label={t('Users.PaymentInterval')}
                disabled={!enabledAdminFieldsEdit || disabled}
                value={formik.values.paymentInterval}
                options={[
                  { label: 'month', value: 'month' },
                  { label: 'year', value: 'year' },
                ]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.paymentInterval ||
                  formik.errors.paymentInterval
                }
                {...getTestProps(testId, 'space-paymentInterval', 'testId')}
              />
              <Input
                name="paymentDueDate"
                type="datetime-local"
                label={t('Users.PaymentDueDate')}
                disabled={!enabledAdminFieldsEdit || disabled}
                value={formik.values.paymentDueDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.status?.errors?.paymentDueDate ||
                  formik.errors.paymentDueDate
                }
                {...getTestProps(testId, 'space-paymentDueDate', 'testId')}
              />
              {space && stripeURL && (
                <Input
                  name="subscriptionId"
                  label={t('Spaces.SubscriptionId')}
                  value={formik.values.subscriptionId || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.subscriptionId ||
                    formik.status?.errors?.subscriptionId
                  }
                  disabled={!enabledAdminFieldsEdit || disabled}
                  helpText={
                    <div className="mt-1">
                      <Trans i18nKey="Spaces.SubscriptionLink">
                        See
                        <LinkButton
                          link={stripeURL}
                          target="_blank"
                          rel="noreferrer"
                          buttonSize={'sm'}
                          buttonColor={'borderless'}
                          additionalClasses={'inline font-normal 2xl:text-sm'}
                          noPaddings
                        >
                          Stripe subscription
                        </LinkButton>
                      </Trans>
                    </div>
                  }
                  {...getTestProps(testId, 'space-subscriptionId', 'testId')}
                />
              )}
            </div>
            <DirtyHandler />
            <ValidationToastHandler />
          </form>
        );
      }}
    </Formik>
  );
};

export default SpaceFormForm;

SpaceFormForm.propTypes = {
  /**
   * On submit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Space to edit
   */
  space: PropTypes.object,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * If navigates after submit represented by react ref
   */
  navigateOnSave: PropTypes.object,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

SpaceFormForm.defaultProps = {
  space: {},
  disabled: false,
  navigateOnSave: {},
  testId: '',
};
