import { createContext } from 'react';
import PropTypes from 'prop-types';

export const ContentObjectFormContext = createContext({});

ContentObjectFormContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Content type before editing
     */
    contentType: PropTypes.shape({}),
    /**
     * If cto is currently editing
     */
    isEditing: PropTypes.bool,
    /**
     * Initial data for content object
     */
    initialCto: PropTypes.shape({}),
    /**
     * On variant create callback
     */
    onVariantCreate: PropTypes.func,
    /**
     * On variant delete callback
     */
    onVariantDelete: PropTypes.func,
    /**
     * User plugins settings
     */
    userPlugins: PropTypes.array,
    /**
     * Should the form have switches for enabling fields
     */
    isPatchable: PropTypes.bool,
    /**
     * List of fields to patch
     */
    overriddenFields: PropTypes.shape({}),
    /**
     * Function to set overridden fields
     */
    setOverriddenFields: PropTypes.func,
    /**
     * Count of edited objects
     */
    editedCount: PropTypes.number,
    /**
     * Unique key for form. Used in plugins to distingushe different forms and in editors (eg. block editor).
     */
    formUniqueKey: PropTypes.any,
  }).isRequired,
};

export default ContentObjectFormContext;
