import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { toast } from 'react-hot-toast';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Hoc
import Authentication from '../../hoc/Authentication/Authentication';

// :: Form
import ChangePasswordForm from '../../form/ChangePasswordForm/ChangePasswordForm';

// :: Lib
import { resetPassword } from '../../lib/flotiq-client/auth-requests';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const ChangePasswordPage = ({ testId }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [darkMode] = useDarkMode();

  const handlePasswordChange = useCallback(
    async (plainPassword) => {
      try {
        const { body, status } = await resetPassword({
          plainPassword,
          plainPasswordRepeat: plainPassword,
          token,
        });
        checkResponseStatus(body, status);
        toast.success(t('Form.FormChangePasswordSuccess'), {
          duration: 10000,
        });
        navigate('/');
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return error.errors;
        }
        toast.error(error.message ? error.message : t('Form.ValidationError'));
        return {
          general: t('Form.ValidationError'),
          ...error.errors,
        };
      }
    },
    [navigate, t, token],
  );

  return (
    <>
      <Helmet>
        <title>{t('Form.FormChangePasswordHeader')}</title>
      </Helmet>
      <Authentication
        headerText={t('Form.FormChangePasswordHeader')}
        paragraphText={t('Form.FormChangePasswordEnterNewPassword')}
        logoLink={process.env.REACT_APP_FLOTIQ_PAGE_URL}
        additionalClasses={twMerge(darkMode && 'dark:bg-gray-900')}
        {...getTestProps(testId, 'authentication', 'testId')}
      >
        <ChangePasswordForm
          onSubmit={handlePasswordChange}
          {...getTestProps(testId, 'form', 'testId')}
        />
      </Authentication>
    </>
  );
};

export default ChangePasswordPage;

ChangePasswordPage.propTypes = {
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

ChangePasswordPage.defaultProps = {
  testId: '',
};
