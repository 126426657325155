import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * Limited information from formik context.
 * For more information, please visit: https://formik.org/docs/api/formik
 *
 * @typedef {object} FormikState
 * @memberof FlotiqPlugins.Events
 *
 * @property {boolean} dirty
 * @property {object} values
 * @property {object} errors
 * @property {object} touched
 * @property {object} status
 * @property {boolean} isValid
 *
 * @property {func} submitForm
 * @property {func} validateForm
 * @property {func} resetForm
 *
 * @property {func} setFieldValue
 * @property {func} setFieldTouched
 */

/**
 * An event that is fired sidebar is rendered
 * Returning anything will embed that result in the sidebar in addition to the original sidebar.
 * It also renders at webhook form page.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.form.sidebar-panel::add"
 * @type {FormAddSidebarPanelEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to replace the default renderer.
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name FormAddSidebarPanelEvent
 *
 * @property {object} contentType Content type that includes the field
 * @property {object} contentObject Data of the content object.
 *    This will be either a new object or the object being edited.
 * 
 * @property {string} formUniqueKey Unique key for form.
 *    Use it to distinguish different forms rendered at the same time.
 *    For example when user compares different versions of the object in the UI.
 *
 * @property {boolean} disabled If form is disabled
 * @property {boolean} duplicate If an object is being duplicated
 * @property {boolean} create If an object is being created
 *
 * @property {object} userPlugins User plugins data with settings
 *
 * @property {FormikState} formik Limited data from formik
 */
export class FormAddSidebarPanelEvent extends IFlotiqPluginEvent {
  contentType;
  contentObject;

  formUniqueKey;

  disabled;
  duplicate;
  create;

  userPlugins;

  formik;
}
