import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import { CaretDownIcon, WarningIcon } from '../../../images/shapes';
import { getTestProps } from '../../../lib/helpers';
import Tooltip from '../../Tooltip/Tooltip';
import VariantCard from '../../VariantCard/VariantCard';

const VariantsGrid = ({
  relation,
  onVariantCreate,
  onVariantDelete,
  testId,
}) => {
  const { t } = useTranslation();
  const [variantsOpen, setVariantsOpen] = useState(false);

  const handleVariantsOpen = useCallback(
    () => setVariantsOpen(!variantsOpen),
    [variantsOpen],
  );

  return (
    <div className="rounded-lg rounded-t-none p-4 space-y-4 border-t border-slate-400 dark:border-slate-700">
      <div className="flex flex-wrap justify-between gap-2">
        <div className="flex items-center gap-2">
          <Button
            buttonColor="borderless"
            type="button"
            buttonSize="sm"
            onClick={handleVariantsOpen}
            additionalClasses="font-normal text-base text-slate-400 "
            additionalIconClasses="min-w-2.5 w-2.5 ml-1"
            iconImage={
              <CaretDownIcon
                className={twMerge('w-2.5', variantsOpen ? '' : 'rotate-180')}
              />
            }
            noPaddings
            {...getTestProps(testId, 'existing-variants', 'testId')}
          >
            {t('MediaEdit.ExistingVariants', {
              length: relation.variants?.length || 0,
            })}
          </Button>

          <Tooltip
            tooltip={t('ContentForm.VariantTooltip')}
            tooltipPlacement="topCenter"
            phoneTooltipPlacement="topCenter"
          >
            <div className="w-fit">
              <WarningIcon className="w-3.5 text-slate-400" />
            </div>
          </Tooltip>
        </div>
        <Button
          buttonColor="borderless"
          type="button"
          buttonSize="sm"
          onClick={() => onVariantCreate(relation)}
          additionalClasses="font-normal text-base"
          noPaddings
          {...getTestProps(testId, 'create-variant', 'testId')}
        >
          {t('MediaEdit.AddVariant')}
        </Button>
      </div>
      {variantsOpen && relation.variants?.length > 0 && (
        <div className="flex gap-4 pb-2 overflow-x-overlay scrollbar-sm">
          {(relation.variants || []).map((variant, idx) => (
            <div key={variant.name}>
              <VariantCard
                variant={variant}
                media={relation}
                onDelete={
                  onVariantDelete ? () => onVariantDelete(relation, idx) : null
                }
                deleteTooltip={t('ContentForm.VariantDeleteTooltip')}
                additionalContainerClasses={'w-56'}
                {...getTestProps(testId, variant.name, 'testId')}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VariantsGrid;

VariantsGrid.propTypes = {
  /**
   * Relation object
   */
  relation: PropTypes.object,
  /**
   * On variant create callback
   */
  onVariantCreate: PropTypes.func,
  /**
   * On variant delete callback
   */
  onVariantDelete: PropTypes.func,
  /**
   * Relations card test id
   */
  testId: PropTypes.string,
};

VariantsGrid.defaultProps = {
  relation: {},
  testId: '',
};
