import { useCallback, useContext, useId, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import FlotiqPlugins from '../../../lib/flotiq-plugins/flotiqPluginsRegistry';
import ElementFromPlugin from '../../../components/ElementFromPlugin/ElementFromPlugin';
import { PluginsManageEvent } from '../../../lib/flotiq-plugins/plugin-events/PluginsManageEvent';
import { ModalInstanceContext } from '../../../contexts/ModalContext';
import ContentObjectForm from '../../../form/ContentObjectForm/ContentObjectForm';
import { getTestProps } from '../../../lib/helpers';
import { PluginSettingsChangedEvent } from '../../../lib/flotiq-plugins/plugin-events/PluginSettingsChangedEvent';
import { PluginsManageFormSchemaEvent } from '../../../lib/flotiq-plugins/plugin-events/PluginsManageFormSchemaEvent';
import usePluginResults from '../../../hooks/usePluginResults';
import CancelButton from '../../../components/Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../../components/Button/predefined/SaveButton/SaveButton';

const ManageModal = ({
  plugin,
  userPlugins,
  contentTypes,
  updatePlugin,
  reloadUserPlugins,
  testId,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const modalInstance = useContext(ModalInstanceContext);
  const formId = useId();

  const closeModal = useCallback(() => {
    modalInstance.resolve();
  }, [modalInstance]);

  const updateSettings = useCallback(
    async (newSettings) => {
      const [newPlugin, errors] = await updatePlugin(
        plugin.id,
        plugin.name,
        true,
        newSettings,
      );

      if (newPlugin) reloadUserPlugins();
      return [newPlugin?.settings, errors];
    },
    [plugin.id, plugin.name, reloadUserPlugins, updatePlugin],
  );

  const handleSubmit = useCallback(
    async (values, userPlugin, pluginOnSubmit) => {
      setIsSaving(true);

      if (pluginOnSubmit) {
        const result = await pluginOnSubmit(values);

        setIsSaving(false);

        if (result?.[0]?.settings) {
          FlotiqPlugins.setPluginSettings(userPlugin.id, result[0].settings);
          FlotiqPlugins.runScoped(
            'flotiq.plugin.settings::changed',
            userPlugin.id,
            new PluginSettingsChangedEvent({ settings: result[0].settings }),
          );

          return [JSON.parse(result[0].settings), result[1]];
        }

        return result;
      } else {
        const [settings, errors] = await updateSettings(JSON.stringify(values));
        setIsSaving(false);

        if (settings) {
          closeModal();
          return [JSON.parse(settings), errors];
        }
        return [settings, errors];
      }
    },
    [closeModal, updateSettings],
  );

  /**
   * @emits FlotiqPlugins."flotiq.plugins.manage::render"
   */
  const pluginRenders = usePluginResults(
    'flotiq.plugins.manage::render',
    PluginsManageEvent,
    {
      plugin: plugin,
      reload: reloadUserPlugins,
      modalInstance,
      contentTypes,
      updateSettings,
    },
    plugin.id,
  );

  /**
   * @emits FlotiqPlugins."flotiq.plugins.manage::form-schema"
   */
  const schemaData = usePluginResults(
    'flotiq.plugins.manage::form-schema',
    PluginsManageFormSchemaEvent,
    {
      plugin: plugin,
      contentTypes,
      modalInstance,
      reload: reloadUserPlugins,
    },
    plugin.id,
  );

  if (schemaData?.length)
    return (
      <>
        {schemaData.map(({ schema, options }) => {
          return (
            <ContentObjectForm
              key={schema.id}
              contentObject={JSON.parse(plugin.settings || '{}')}
              contentType={{ ...schema, nonCtdSchema: true }}
              onSubmit={(values) =>
                handleSubmit(values, plugin, options?.onSubmit)
              }
              onValidate={options?.onValidate}
              disabledBuildInValidation={options?.disbaledBuildInValidation}
              hasInitialData={!!plugin.settings}
              formId={formId}
              userPlugins={userPlugins}
              disabled={isSaving}
              additionalFormClasses="pb-0"
              isEditing
              {...getTestProps(testId, schema.id, 'testId')}
            />
          );
        })}
        <div
          className={twMerge(
            'w-full fixed left-0 bottom-0 flex items-center justify-between p-3 md:px-10 space-x-5',
            'border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900',
          )}
        >
          <CancelButton
            onClick={closeModal}
            disabled={isSaving}
            {...getTestProps(testId, 'cancel', 'testId')}
          />
          <SaveButton
            form={formId}
            isSaving={isSaving}
            {...getTestProps(testId, 'save', 'testId')}
          />
        </div>
      </>
    );

  return pluginRenders?.length ? (
    <ElementFromPlugin results={pluginRenders} />
  ) : null;
};

export default ManageModal;

ManageModal.propTypes = {
  /**
   * Plugin data
   */
  plugin: PropTypes.object.isRequired,
  /**
   * Realod plugins setting callback
   */
  reloadUserPlugins: PropTypes.func.isRequired,
};

ManageModal.defaultProps = {};
