import { useCallback, useContext, useId, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import useLocalStorageState from 'use-local-storage-state';

// :: Hooks
import useToken from '../../../hooks/useToken';
import useDebounceCallback from '../../../hooks/useDebounceCallback';
import {
  useContentType,
  useContentTypes,
  useDefinitionsCount,
} from '../../../hooks/api';
import useApiErrorsToast from '../../../hooks/api/useApiErrorsToast';
import useSelectedSpace from '../../../hooks/useSelectedSpace';
import { useFeaturedImages } from '../../../hooks/api/useFeaturedImages';

// :: Helpers
import { getTestProps } from '../../../lib/helpers';

// :: Contexts
import {
  ModalInstanceContext,
  useModals,
} from '../../../contexts/ModalContext';

// :: Api
import { saveNewCTO } from '../../../lib/flotiq-client/api-helpers';

// :: Components
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import PickType from './content/PickType';
import CreateObject from './content/CreateObject';
import CancelButton from '../../Button/predefined/CancelButton/CancelButton';
import SaveButton from '../../Button/predefined/SaveButton/SaveButton';

const Step = ({ number, label, checked, onClick, testId }) => (
  <div
    className={twMerge(
      'flex flex-wrap gap-2 items-center',
      !checked && 'text-slate-400',
      onClick && 'cursor-pointer hover:text-blue',
    )}
    onClick={onClick}
    {...getTestProps(testId, `create-${number}-step`)}
  >
    <div
      className={twMerge(
        'flex justify-center items-center h-7 w-7 rounded-full',
        checked ? 'bg-blue text-white' : 'border border-blue',
      )}
    >
      {number}
    </div>
    {label}
  </div>
);

const CreateObjectContentModal = ({
  relationType,
  onMediaUpload,
  isMultiple,
  testId,
}) => {
  const { t } = useTranslation();
  const modal = useModals();
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const modalInstance = useContext(ModalInstanceContext);
  const formId = useId();
  const [user, setUser] = useLocalStorageState('cms.user');

  const [type, setType] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState({});

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [queryBy, setQueryBy] = useState('label');

  const onCancel = useCallback(() => {
    modalInstance.resolve(null);
  }, [modalInstance]);

  const onSave = useCallback(() => {
    if (!type) onCancel();
    if (type === '_media') {
      modalInstance.resolve(Object.values(selectedMedia));
    }
  }, [modalInstance, onCancel, selectedMedia, type]);

  const saveNewObject = useCallback(
    async (values) => {
      setIsSaving(true);
      const [formikValues, hasErrors] = await saveNewCTO(
        jwt,
        space,
        values,
        type,
        user,
        setUser,
        t,
      );
      setIsSaving(false);
      if (!hasErrors) modalInstance.resolve(formikValues[0]);
      return formikValues;
    },
    [jwt, space, type, user, setUser, t, modalInstance],
  );

  const ctdsParams = useMemo(
    () => ({
      limit: 16,
      order_by: 'label',
      order_direction: 'asc',
      page,
      [queryBy]: query,
    }),
    [page, query, queryBy],
  );

  const ctdsHookOptions = useMemo(
    () => ({
      pause: !!relationType,
    }),
    [relationType],
  );

  const {
    data: contentTypes,
    isLoading: contentTypesAreLoading,
    errors: contentTypesErrors,
    pagination: contentTypesPagination,
  } = useContentTypes(ctdsParams, ctdsHookOptions);

  const ctdHookOptions = useMemo(
    () => ({
      pause: !relationType,
    }),
    [relationType],
  );

  const {
    entity: contentType,
    isLoading: contentTypeIsLoading,
    errors: contentTypeErrors,
  } = useContentType(relationType, null, ctdHookOptions);

  const finalContentTypes = useMemo(() => {
    if (relationType) return contentType ? [contentType] : [];
    return contentTypes.filter((ctd) => !ctd.internal || ctd.name === '_media');
  }, [relationType, contentType, contentTypes]);

  const ctdCountsParams = useMemo(
    () => ({
      content_type: relationType
        ? [relationType]
        : finalContentTypes?.map((element) => element.name),
    }),
    [finalContentTypes, relationType],
  );

  const ctdCountsOptions = useMemo(
    () => ({
      pause: !relationType && contentTypesAreLoading,
    }),
    [contentTypesAreLoading, relationType],
  );

  const {
    entity: ctdCounts,
    isLoading: ctdCountsAreLoading,
    errors: ctdCountsErrors,
  } = useDefinitionsCount(ctdCountsParams, ctdCountsOptions);

  useApiErrorsToast(contentTypesErrors);
  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(ctdCountsErrors);

  const setSearchBy = useCallback((_, value) => {
    setQueryBy(value);
    setPage(1);
  }, []);

  const setFilterQuery = useCallback((event) => {
    setQuery(event.target.value);
    setPage(1);
  }, []);

  const handleQueryChange = useDebounceCallback(setFilterQuery, 500);

  const onPickTypeClick = useCallback(async () => {
    if (type) {
      const result = await modal.confirmation(
        t('ContentForm.Relation.PickTypeConfirm'),
      );
      if (!result) return;
    }
    setType(null);
  }, [modal, t, type]);

  const breadcrumbs = useMemo(
    () => [
      {
        key: 'pick',
        children: (
          <Step
            number={1}
            label={t('ContentForm.Relation.PickType')}
            checked
            onClick={onPickTypeClick}
            testId={testId}
          />
        ),
      },
      {
        key: 'create',
        children: (
          <Step
            number={2}
            label={t('ContentForm.Relation.CreateObject')}
            checked={!!type}
          />
        ),
      },
    ],
    [onPickTypeClick, t, testId, type],
  );

  const selectedType = useMemo(() => {
    if (!type) return null;
    return finalContentTypes.find((ctd) => ctd.name === type);
  }, [finalContentTypes, type]);

  const selectMedia = useCallback(
    (media) => {
      setSelectedMedia((prevSelected) => {
        if (!media.id) return prevSelected;
        const mediaData = {
          type: 'internal',
          dataUrl: `/api/v1/content/_media/${media.id}`,
        };
        if (!isMultiple)
          return {
            [media.id]: mediaData,
          };
        const newSelected = { ...prevSelected };
        if (newSelected[media.id]) delete newSelected[media.id];
        else {
          newSelected[media.id] = mediaData;
        }
        return newSelected;
      });
    },
    [isMultiple],
  );

  const [featuredImages, featuredImagesLoading] =
    useFeaturedImages(finalContentTypes);

  return (
    <>
      <Breadcrumbs
        additionalClasses="h-fit !p-0 items-center !border-0 mb-4 md:mb-6"
        breadcrumbs={breadcrumbs}
        additionalBreadcrumbClasses="pr-7"
      />
      {!type ? (
        <PickType
          data={finalContentTypes}
          featuredImages={featuredImages}
          featuredImagesLoading={featuredImagesLoading}
          page={page}
          pagination={
            relationType
              ? {
                  total_pages: 1,
                }
              : contentTypesPagination
          }
          setPage={setPage}
          setType={setType}
          ctdCounts={ctdCounts?.data}
          isLoading={
            (relationType ? contentTypeIsLoading : contentTypesAreLoading) ||
            ctdCountsAreLoading
          }
          errors={contentTypeErrors || contentTypesErrors}
          query={query}
          handleQueryChange={handleQueryChange}
          queryBy={queryBy}
          setSearchBy={setSearchBy}
          showFilters={!relationType}
          {...getTestProps(testId, 'pick', 'testId')}
        />
      ) : (
        <CreateObject
          selectedType={selectedType}
          formId={formId}
          onSubmit={saveNewObject}
          disabled={isSaving}
          onMediaUpload={onMediaUpload}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          selectMedia={selectMedia}
          selectedMedia={selectedMedia}
          isMultiple={isMultiple}
          {...getTestProps(testId, 'create', 'testId')}
        />
      )}
      <div
        className="w-full fixed left-0 bottom-0 flex items-center justify-center p-3 space-x-5
        border-t border-gray dark:border-slate-800 bg-white dark:bg-gray-900"
      >
        <CancelButton
          onClick={onCancel}
          disabled={isSaving || isUploading}
          {...getTestProps(testId, 'cancel-new-cto', 'testId')}
        />
        <SaveButton
          form={formId}
          isSaving={isSaving}
          onClick={onSave}
          disabled={isSaving || isUploading}
          {...getTestProps(testId, 'save-new-cto', 'testId')}
        />
      </div>
    </>
  );
};

export default CreateObjectContentModal;

CreateObjectContentModal.propTypes = {
  /**
   * Array of object data
   */
  relationType: PropTypes.string,
  /**
   * On media upload callback
   */
  onMediaUpload: PropTypes.func,
  /**
   * If relation is multiple
   */
  isMultiple: PropTypes.bool,
  /**
   * Create object modal
   */
  testId: PropTypes.string,
};

CreateObjectContentModal.defaultProps = {
  relationType: '',
  onMediaUpload: /* istanbul ignore next */ () => null,
  isMultiple: false,
  testId: '',
};
