import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getTestProps } from '../../../../lib/helpers';
import HelpErrorTextsTemplate from '../../../../components/HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import CtoCustomField from '../../../../components/CtoCustomField/CtoCustomField';
import { getIn } from 'formik';
import Button from '../../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import { CaretUpIcon, QuestionMarkCircleIcon } from '../../../../images/shapes';
import { twMerge } from 'tailwind-merge';
import Tooltip from '../../../../components/Tooltip/Tooltip';

const ListDefaultRender = ({
  listName,
  label,
  itemsProps,
  itemsSchema,
  highListName,
  error,
  helpText,
  testId,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const itemsOrder = useMemo(() => itemsProps?.order || [], [itemsProps]);

  const renderListField = useCallback(
    (key, itemName) => {
      const itemProps = getIn(itemsProps, `propertiesConfig.${itemName}`);
      const itemSchema = getIn(itemsSchema, `properties.${itemName}`);
      const isRequired =
        itemsSchema.required?.findIndex((key) => key === itemName) > -1;
      const label = itemProps.hidden ? (
        <div className="inline-flex items-center gap-1">
          {itemProps.label || itemName}
          <Tooltip
            tooltip={t('PropertyForm.HiddenListPropTooltip')}
            tooltipPlacement="topLeft"
            phoneTooltipPlacement="topLeft"
          >
            <QuestionMarkCircleIcon className="w-3 text-blue" />
          </Tooltip>
        </div>
      ) : null;

      if (itemProps.inputType === 'object')
        return (
          <ListDefaultRender
            listName={itemName}
            label={itemProps.label || key}
            itemsProps={itemProps.items}
            itemsSchema={itemSchema.items}
            helpText={itemProps.helpText}
            highListName={listName}
            testId={testId}
          />
        );
      return (
        <CtoCustomField
          name={key}
          properties={itemProps}
          schema={itemSchema}
          isRequired={isRequired}
          label={label}
          ignoreHidden
          testId={testId}
        />
      );
    },
    [itemsProps, itemsSchema, listName, t, testId],
  );

  return (
    <div>
      <label className="text-sm text-slate-400 mb-1">
        {label}
        <Button
          onClick={() => setOpen(!open)}
          buttonColor="borderless"
          buttonSize="xs"
          iconImage={
            <CaretUpIcon
              className={twMerge(
                'w-2 rotate-180 text-blue',
                open ? 'rotate-0' : 'rotate-180',
              )}
              {...getTestProps(testId, 'list-default-open')}
            />
          }
          additionalIconClasses="ml-1"
          noPaddings
          additionalClasses="mr-2"
        >
          {t('ContentTypeForm.ShowProperties')}
        </Button>
      </label>
      {open && (
        <div className="relative my-2">
          <div className="rounded-md p-4 bg-slate-400/10 border border-slate-200 dark:border-slate-700">
            {itemsOrder.length > 0 && (
              <div className="mb-2 space-y-2">
                {itemsOrder.map((itemName) => {
                  const itemPath = `schema.items${
                    highListName ? `.properties.${listName}.items` : ''
                  }.properties.${itemName}.default`;
                  return (
                    <div key={itemPath}>
                      {renderListField(itemPath, itemName)}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={typeof error === 'string' ? error : ''}
        testId={testId}
      />
    </div>
  );
};

export default ListDefaultRender;

ListDefaultRender.propTypes = {
  /**
   * Field name
   */
  listName: PropTypes.string,
  /**
   * Field label above items
   */
  label: PropTypes.node,
  /**
   * List items properties
   */
  itemsProps: PropTypes.object,
  /**
   * List items schema
   */
  itemsSchema: PropTypes.object,
  /**
   * Parent list field name
   */
  highListName: PropTypes.string,
  /**
   * Errors for fields
   */
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ),
    PropTypes.string,
  ]),
  /**
   * Help text under items
   */
  helpText: PropTypes.node,
  /**
   * Test id for field
   */
  testId: PropTypes.string,
};

ListDefaultRender.defaultProps = {
  listName: '',
  label: '',
  itemsProps: {},
  itemsSchema: {},
  error: null,
  highListName: '',
  helpText: '',
  testId: '',
};
